


import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import Axios from "axios";

import moment from "moment";

@Component({ components: {} })
export default class ViewAllReport extends Vue {
  public allReports = [];
  public warningInfo = [];
  public incomingType = null;
  public header = null;

  public async getLoanNotSubmittedByBroker() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "superAdminDashboard/getLoanNotSubmittedByBroker");
      
      this.allReports = response.data.brokerCountInfo;
    } catch (error) {
      console.log(error);
    }
  }

  public async getCriticalWarningReport() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "superAdminDashboard/getCriticalWarningReport");
      this.allReports = response.data.warningInfo;
    } catch (error) {
      console.log(error);
    }
  }

  public async getBrokerPullThrough() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdminDashboard/getBrokerPullThrough",
        {});
      this.allReports = response.data.data;
    } catch (error) {
      console.log(error);
    }
  }

  public changeClassForResponsive(){
    if(this.incomingType == 'broker'){
      return 'admin_view_broker'
    }else if(this.incomingType == 'warning'){
      return 'admin_view_critical'
    }else{
      return 'admin_view_pullth'
    }
  }
  mounted() {
    this.incomingType = this.$route.query.type;
    
    if (this.$route.query.type == "broker") {
      this.getLoanNotSubmittedByBroker();
      this.header = 'Broker who have not submitted a loan in 15 days'
      
    } else if (this.$route.query.type == "warning") {
      this.getCriticalWarningReport();
      this.header = 'Borrowers with Critical Warning'
    }else{
      this.getBrokerPullThrough();
      this.header = 'Pull through % by Brokers'
    }
  }
}
